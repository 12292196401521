.layout {
  background-color: white;
  align-items: center;
  min-height: 100vh;

  &__header {
    background-color: $color-primary-dark !important;
    width: 100%;
    height: fit-content !important;
    padding-inline: 40px !important;
    padding: 40px;

    &_title {
      color: white !important;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    &_sub {
      color: white !important;
    }
  }

  .login {
    padding: 30px 0px;
    width: 80%;

    @media screen and (min-width: $md) {
      width: 50%;
    }

    &__header {
      width: 100%;
      text-align: center;

      &_logo {
        img {
          width: 40%;
          max-width: 120px;
        }
      }
    }

    .primary-btn {
      margin-top: 40px;
      margin-bottom: 40px;
      font-weight: 600;
      border-radius: 6px;
    }

    &__form {
      width: 100%;
      margin-top: 40px;

      .ant-form-item {
        margin-bottom: 10px;

        input,
        span {
          font-size: 20px;
          border-radius: 2px;
        }
      }

      &_button {
        @include button;
        margin-top: 40px;
        border-radius: 2px;
        border: 1px solid $color-gray-dark;
        font-weight: 700;
        font-size: 16px;

        background: $color-primary !important;
        border-color: $color-primary !important;
        color: white !important;

        &:hover {
          background-color: grey !important;
          border-color: grey !important;
          color: white !important;
        }
      }
    }
  }

  .recover-link {
    text-align: center;
    width: 100%;
    color: $color-primary;
    font-weight: 600;

    &:hover {
      color: $color-primary-dark;
    }
  }
}
