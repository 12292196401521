.bottom-nav {
    width: 100%;
    height: 50px;

    .nav-item {
        text-align: center;
        font-size: x-large;

        a {
            color: $color-gray;

            &:hover {
                color: $color-gray-dark;
            }
        }
    }
}