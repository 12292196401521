@mixin button {
  gap: 8px;
  // height: 48px;
  transition: all 0.3s;
  border-radius: 6px;
  font-weight: 600;
  height: fit-content;
  padding: 0px;

  span {
    padding: 10px 16px;
  }

  &:hover {
    cursor: pointer;
  }
}