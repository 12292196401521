@import './base/variables';
@import './base/mixins.scss';
@import './base/typo.scss';

@import './components/BottomNav.scss';
@import './components/Loading.scss';
@import './components/TopNav.scss';

@import './views/EventScreen.scss';
@import './views/LoginScreen.scss';

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Raleway', sans-serif !important;
  background-color: #e5e5e5;
}

.ant-layout {
  font-family: 'Roboto', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 p {
  margin: 0 !important;
  margin-bottom: 0 !important;
  font-family: 'Raleway', sans-serif !important;
}

.font-roboto {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 p {
    font-family: 'Roboto', sans-serif !important;
  }
}

.main-header {
  background-color: inherit !important;
  color: black;
  padding-inline: 0;
  padding: 20px !important;
  height: fit-content !important;

  @media screen and (min-width: $md) {
    padding: 20px 25% !important;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text-red {
  color: red;
}

.border-red {
  border: 1px solid red;
}

.text-gray {
  color: rgba(0, 0, 0, 0.65);
}

.margin-x {
  margin-left: 6px;
  margin-right: 6px;
}

.margin-y {
  margin-top: 8px;
  margin-bottom: 8px;
}

.w-full {
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

.secondary-btn {
  @include button;
  color: black;
  background: White;
  font-weight: 400;

  &:hover {
    border-color: #d9d9d9 !important;
    color: black !important;
  }

  &:disabled,
  &.ant-btn-disabled {
    cursor: not-allowed;
    border: 1px solid #d9d9d9 !important;
    color: $color-gray !important;
    background: transparent;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.02), 0px 3px 6px -4px rgba(0, 0, 0, 0.06);
    filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.04));
  }
}

.primary-btn {
  @include button;
  color: white;
  background: $color-primary;
  border: 1px solid $color-primary;

  &:hover {
    background: $color-primary !important;
    border-color: $color-primary !important;
    color: white !important;
  }

  &:disabled,
  &.ant-btn-disabled {
    cursor: not-allowed;
    border: 1px solid $color-gray-dark !important;
    color: $color-gray !important;
    // color: #d9d9d9 !important;
    background: $color-gray-dark !important;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.02), 0px 3px 6px -4px rgba(0, 0, 0, 0.06);
    filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.04));
  }
}

.ant-btn {
  &-default,
  &-dashed {
    &.ant-btn-dangerous {
      color: #cf1322;
      border-color: #cf1322;
      background-color: transparent;
    }
  }

  &-primary {
    &.ant-btn-dangerous {
      background-color: #cf1322;
    }
  }
}

.main-footer {
  background: #e5e5e5 !important;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 24px !important;

  @media screen and (min-width: $md) {
    padding: 24px 25% !important;
  }
}

.site-layout {
  background-color: #e5e5e5;

  &-content {
    justify-content: center;
    width: 100%;

    &-inner {
      @media screen and (min-width: $md) {
        // max-width: 50%;
        padding: 10px 25%;

        .center-layout-md {
          text-align: center;
        }
      }
    }
  }
}

.tier {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1px 8px;
  margin-left: 10px;
  font-size: 12px;

  &-platinum {
    background: linear-gradient(90deg, #dedede 0%, #ffffff 60.12%);
  }
}

.sider-trigger {
  position: absolute;
  top: 10px;
  left: -35px;
  background: transparent;
  border: none;
  color: black !important;
}

.ant-layout-sider-collapsed {
  .sider-trigger {
    position: absolute;
    top: 20px;
    left: -35px;
    background: transparent;
    border: none;
    color: black !important;
  }
}

.item__subtitle__location {
  font-size: 12px;
  font-style: italic;
}
