.top-nav {

    // padding-left: ;
    height: 100%;
    display: flex;

    .nav-item {
        text-align: center;
        font-size: x-large;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: start;

        span {
            font-family: 'Roboto', sans-serif !important;
        }

        span:first-child {
            padding-right: 10px;
        }

        h2 {
            color: black;
            font-weight: 800;
        }

    }
}