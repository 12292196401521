.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;

  &.main {
    height: 50vh;
  }
}

.loader {
  color: $color-primary;
}