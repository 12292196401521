/* Screen Sizes */

//xs: screen < $sm
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
//xxl: screen > $xl

/* Colors */
$color-primary: #CF1322;
$color-primary-dark: #A8071A;
$color-primary-light: #FFF1F0;
$color-gray: #BFBFBF;
$color-gray-dark: #D9D9D9;