.item {
  margin-bottom: 10px;
  transition: 0.3s all;

  &:hover {
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.116);
  }

  &:active {
    background-color: rgba(128, 128, 128, 0.329);
  }

  &__title {
    font-weight: bold;
    margin-bottom: 6px;
  }

  &__subtitle {
    margin-bottom: 6px;
  }

  &__divider {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.06);
    border: none;
  }
}
