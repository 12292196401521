.speakers {
  margin: 20px 0px;

  &__title {
    margin-bottom: 1.2em;
    h3 {
      font-weight: bold;
    }
  }

  &__avatars {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 450px) {
      justify-content: flex-start;
    }

    div {
      max-width: 150px;
      flex-grow: 1;
      height: auto;
    }

    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 6px;
      text-align: center;

      img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}

.description {
  margin: 20px 0px;
  padding-bottom: 1em;

  &__title {
    margin-bottom: 1em;
    h3 {
      font-weight: bold;
    }
  }

  &__content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}
